import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

import Layout from "../../components/Layout/Layout"
import RecipeDetailPage from "../../components/UI/RecipeDetailPage/RecipeDetailPage"
import RelatedRecipes from "../../components/UI/RelatedRecipes/RelatedRecipes"
import GlobalContext from "../../context/GlobalContext"
import Link from "../../components/UI/Link/Link"
import { setDataLayer } from "../../helpers/dataLayerSetter"

import componentMaker from "../../helpers/ComponentMaker/componentMaker"
import { API_RECIPE_SHAPE, API_SIMILAR_RECIPE_SHAPE } from "../../constants/shapes.constants"
import { makePerex } from "../../helpers/DataConvertor.helper"
import { exportMeta } from "../../helpers/utils"

import SchemaSnippet from "../../components/SchemaSnippet/SchemaSnippet"
import { gTagScriptConsent } from "../../components/Scripts/Gtm.Head"

const recipeTime = time => {
    return `PT${time}M`
}

const prepareNutritions = nutritionList => {
    if (!Array.isArray(nutritionList)) return {}

    return nutritionList.reduce((prev, curr) => {
        prev[curr.NutritionalItemType] = `${curr.Quantity}${curr.Unit} ${curr.NutritionalItemType}`

        return prev
    }, {})
}

const RecipeDetail = ({ path, pageContext }) => {
    const { apiData, similarRecipes, globalProps, seoData, pageType, dataLayer, globalLabels, brandTheme } = pageContext
    const { staticResult, contentfulRecipeDetail } = pageContext.pageProps

    const finalSeoData = seoData || {}
    finalSeoData.title = apiData?.RecipeName
    finalSeoData.metaDescription = makePerex(apiData?.RomanceText, 200)
    finalSeoData.featuredImage = apiData?.PhotoURL
    finalSeoData.ogUrl = path
    finalSeoData.metaKeywords = exportMeta(apiData?.Metatags).keywords
    finalSeoData.sku = apiData?.RecipeID
    finalSeoData.category = apiData?.Brands[0]

    setDataLayer(dataLayer, finalSeoData, pageType)

    const nutritions = prepareNutritions(apiData?.NutritionList)

    return (
        <GlobalContext labels={globalLabels} brandTheme={brandTheme}>
            <Layout path={path} seoData={finalSeoData} globalProps={globalProps}>
                <RecipeDetailPage apiData={apiData} shareUrl={path} />

                <SchemaSnippet
                    snippet={`
                {
                    "@context": "https://schema.org/",
                    "@type": "Recipe",
                    "name": "${apiData?.RecipeName.replace(/(<([^>]+)>)/ig, '')}",
                    "image": "${apiData?.PhotoURL}",
                    "description": "${apiData?.RomanceText.replaceAll("\"", "'").replace(/(<([^>]+)>)/ig, '')}",
                    "keywords": "${exportMeta(apiData?.Metatags).keywords}",
                    "author": {
                        "@type": "Person",
                        "name": "Snackworks"
                    },
                    "datePublished": "${dayjs().format("YYYY-MM-DD")}",
                    "prepTime": "${recipeTime(apiData?.PreparationTime)}",
                    "totalTime": "${recipeTime(apiData?.TotalTime)}",
                    "recipeCategory": "${apiData?.RecipeCategorySubCategories?.[0]?.CategoryName.replace(/(<([^>]+)>)/ig, '')}",
                    "recipeYield": "${apiData?.NumberOfServings?.match(/\d+/)?.[0]}",
                    "nutrition": {
                        "@type": "NutritionInformation",
                        "servingSize": "${apiData?.NumberOfServings?.match(/\d+/)?.[0]}",
                        "calories": "${nutritions["Calories"]}",
                        "cholesterolContent": "${nutritions["Cholesterol"]}",
                        "proteinContent": "${nutritions["Protein"]}",
                        "sodiumContent": "${nutritions["Sodium"]}",
                        "transFatContent": "${nutritions["Trans fatty acids"]}"
                    },
                    "recipeIngredient": [
                        ${apiData?.IngredientList.map(
                        e => `"${e.QuantityText.replace(/(<([^>]+)>)/ig, '')} ${e.QuantityUnit.replace(/(<([^>]+)>)/ig, '')} ${e.IngredientName.replace(/(<([^>]+)>)/ig, '')}${e.PostPreparation.replace(/(<([^>]+)>)/ig, '')}"`
                    ).join(",\n")}
                    ]
                }
                `}
                />

                <div className="print:hidden">
                    {componentMaker(staticResult.contentComponents)}
                </div>

                <RelatedRecipes similarRecipes={similarRecipes} link={contentfulRecipeDetail.relatedRecipesLink} />
            </Layout>
        </GlobalContext>
    )
}

RecipeDetail.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        pageProps: PropTypes.shape({
            staticResult: PropTypes.object,
            contentfulRecipeDetail: PropTypes.shape({
                relatedRecipesLink: PropTypes.shape(Link.propTypes),
            }),
        }),
        apiData: PropTypes.shape(API_RECIPE_SHAPE),
        similarRecipes: PropTypes.arrayOf(PropTypes.shape(API_SIMILAR_RECIPE_SHAPE)),
        globalProps: PropTypes.object,
        seoData: PropTypes.object,
        pageType: PropTypes.string,
        dataLayer: PropTypes.object,
        globalLabels: PropTypes.object,
        brandTheme: PropTypes.shape({
            themeName: PropTypes.string,
        }),
    }),
}

/**
 * Adding to Layout ?
 */
export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}

    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
            <script src="https://shop.basketful.co/basketful.js" async />

        </>
    )
}

export default RecipeDetail
